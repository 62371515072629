<template>
  <!-- 邀请好友 -->
  <div>
    <headbox
      title="邀请好友"
      bgColor="#f94440"
      iswhite="true"
      :isbackPath="true"
      path="person"
    ></headbox>
    <div class="inviteFrients">
      <div class="body">
        <img
          src="@/assets/images/icon_inviteFriends@2x.png"
          alt=""
          class="bg"
        />
        <div class="info">
          <img
            src="@/assets/images/pic_head portrait_com@2x.png"
            alt=""
            class="avatar"
          />
<!--          <div class="name">{{ userInfo.realName }}</div>-->
          <div class="describe">您的邀请码为</div>
          <div class="code">{{ userInfo.referrerCode }}</div>
          <div ref="qrcode" id="qrcode" class="qrcode"></div>
          <div class="codeDescribe">微信好友扫一扫即可邀请绑定好友</div>
        </div>
      </div>
      <div class="count">您已邀请{{ totalNumber }}位好友</div>
      <div style="background: #f94440">
        <div v-if="ishow">
          <van-empty
            class="custom-image"
            :image="require('@/assets/images/pic_no information@2x.png')"
            description="暂无数据!"
          />
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="load"
          v-if="!ishow"
        >
          <div class="list">
            <div
              class="listContent flex"
              v-for="(item, index) in referrerList"
              :key="index"
            >
              <div class="time">{{ item.created | formatDate_("yyyy-MM-dd") }}</div>
              <div class="name" style="width: 80px">{{ item.realName }}</div>
              <div class="name">{{ item.phone }}</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import headbox from "@/components/head";
import { getUserReferrer } from "@/utils/api";
import { formatDate_ } from "@/utils/filters";

export default {
  components: {
    QRCode,
    headbox
  },
  data() {
    return {
      userInfo: {}, //用户信息
      referrerList: [], // 推荐列表
      loading: false,
      finished: false,
      page: 0,
      size: 10,
      ishow: true, // 没有数据显示的
      totalNumber: 0
    };
  },
  filters: {
    formatDate_
  },
  created() {
    this.load();
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
    this.$nextTick(() => {
      new QRCode("qrcode", {
        width: 120,
        height: 120,
        text:
          process.env.VUE_APP_WEBSITE +
          "/?referrerCode=" +
          this.userInfo.referrerCode
      });
    });
  },
  methods: {
    async load() {
      try {
        const res = await getUserReferrer({
          page: this.page,
          size: this.size
        });
        this.page = res.number + 1;
        this.referrerList.push(...res.content);
        this.totalNumber = res.totalElements;
        this.loading = false;
        if (this.referrerList.length === 0) {
          this.ishow = true;
        }
        this.ishow = false;
        if (res.last == true) {
          this.finished = true;
        }
      } catch {
        this.ishow = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped="true">
.inviteFrients {
  background: #f94440;
  width: 100%;
  height: 100vh;
  z-index: -2;
  position: relative;
  .body {
    height: 800px;
    .bg {
      position: absolute;
      top: 20px;
      left: 32px;
      width: 686px;
      height: 758px;
      z-index: -1;
    }
    .info {
      text-align: center;
      .avatar {
        width: 128px;
        height: 128px;
        margin-top: 38px;
      }
      .name {
        margin-top: 16px;
        font-size: 32px;
        color: #39394d;
        font-weight: 500;
      }
      .describe {
        font-size: 28px;
        color: #9494a3;
        margin-top: 66px;
      }
      .code {
        font-weight: 500;
        color: #404053;
        margin-top: 16px;
        font-size: 40px;
      }
      .qrcode {
        width: 240px;
        height: 240px;
        margin: 32px auto;
      }
      .codeDescribe {
        font-size: 28px;
        color: #8f8f9f;
        margin-top: 16px;
      }
    }
  }
  .count {
    text-align: center;
    margin: 32px 0;
    font-size: 32px;
    color: #ffffff;
    font-weight: 500;
  }
  .list {
    background: #ffffff;
    margin: 0 32px 30px;
    .listContent {
      padding: 0 32px;
      height: 67px;
      line-height: 67px;
      border-bottom: 1px solid #d8d8d8;
      .time {
        font-size: 24px;
        color: #8f8f9f;
      }
      .name {
        margin-left: 50px;
        font-size: 32px;
        color: #39394d;
      }
    }
  }
}
::v-deep {
  .van-empty__description {
    color: #ffffff;
    font-size: 24px;
  }
  .van-list__finished-text {
    color: #ffffff;
  }
}
</style>
