var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", {
        attrs: {
          title: "邀请好友",
          bgColor: "#f94440",
          iswhite: "true",
          isbackPath: true,
          path: "person"
        }
      }),
      _c("div", { staticClass: "inviteFrients" }, [
        _c("div", { staticClass: "body" }, [
          _c("img", {
            staticClass: "bg",
            attrs: {
              src: require("@/assets/images/icon_inviteFriends@2x.png"),
              alt: ""
            }
          }),
          _c("div", { staticClass: "info" }, [
            _c("img", {
              staticClass: "avatar",
              attrs: {
                src: require("@/assets/images/pic_head portrait_com@2x.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "describe" }, [_vm._v("您的邀请码为")]),
            _c("div", { staticClass: "code" }, [
              _vm._v(_vm._s(_vm.userInfo.referrerCode))
            ]),
            _c("div", {
              ref: "qrcode",
              staticClass: "qrcode",
              attrs: { id: "qrcode" }
            }),
            _c("div", { staticClass: "codeDescribe" }, [
              _vm._v("微信好友扫一扫即可邀请绑定好友")
            ])
          ])
        ]),
        _c("div", { staticClass: "count" }, [
          _vm._v("您已邀请" + _vm._s(_vm.totalNumber) + "位好友")
        ]),
        _c(
          "div",
          { staticStyle: { background: "#f94440" } },
          [
            _vm.ishow
              ? _c(
                  "div",
                  [
                    _c("van-empty", {
                      staticClass: "custom-image",
                      attrs: {
                        image: require("@/assets/images/pic_no information@2x.png"),
                        description: "暂无数据!"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            !_vm.ishow
              ? _c(
                  "van-list",
                  {
                    attrs: {
                      finished: _vm.finished,
                      "finished-text": "没有更多了"
                    },
                    on: { load: _vm.load },
                    model: {
                      value: _vm.loading,
                      callback: function($$v) {
                        _vm.loading = $$v
                      },
                      expression: "loading"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "list" },
                      _vm._l(_vm.referrerList, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "listContent flex" },
                          [
                            _c("div", { staticClass: "time" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDate_")(
                                    item.created,
                                    "yyyy-MM-dd"
                                  )
                                )
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "name",
                                staticStyle: { width: "80px" }
                              },
                              [_vm._v(_vm._s(item.realName))]
                            ),
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.phone))
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }